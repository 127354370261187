import React, { useEffect, useState } from 'react';
import '../styles/Root.css'
import Comment from '../Components/Comment';
import Emotions from '../Components/Emotions';
import Phone from '../Components/Phone';
import FileAttach from '../Components/FileAttach';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {PhoneNumberUtil} from "google-libphonenumber"

import cyberion from "../images/cyberion.svg"
import traffic from "../images/traffic.png"

const Root = () => {
  const navigate = useNavigate()

  const [sending, setSending] = useState(false)

  const [comment, setComment] = useState("")
  const [emotion, setEmotion] = useState(null)
  const [phone, setPhone] = useState("")
  const [isAnonymous, setIsAnonymous] = useState(false)
  const [file, setFile] = useState(null)

  const {club} = useParams()

  const [clubProps, setClubProps] = useState({})


  console.log(club)
  useEffect(() => {
    if(club && club !== '404'){
      const fetchData = async () => {
        await axios
          .get(`https://api.cyberion.com.ua/feedback/${club}`)
          .then(res => {
            if (res.data === "") return navigate("/404")
            setClubProps(res.data)
          })
          .catch(err => {
            console.log(err);
            navigate("/404")
          })
      }
      fetchData()
    }
    // eslint-disable-next-line
  }, [club])

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  }

  const handleSend = async () => {
    console.log(isPhoneValid(phone));
    if (comment === "" || (!isAnonymous && !isPhoneValid(phone)) || (emotion !== 0 && emotion !== 1 && emotion !== 2)) return

    setSending(true)

    const formData = new FormData()

    formData.append("comment", comment)
    formData.append("phone", isPhoneValid(phone) ? phone : "Anonymous")
    formData.append("emotion", emotion === 0 ? "bad" : emotion === 1 ? "neutral" : "good")
    formData.append("club", club)
    formData.append("partnersEmail", clubProps.partnersEmail ? clubProps.partnersEmail : "")
    formData.append("file", file)

    await axios
      .post('https://api.cyberion.com.ua/mails/feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => navigate("/thanks"))
      .catch(err => console.error(err))
  }
  return (
    <div className={"root"}>
      {
        sending &&
        <div className="sending">
          <div className="sending-line">

          </div>
        </div>
      }
      <div className="root-head">
        {
          clubProps?.franchise?.toLowerCase() === "cyberion" &&
          <img src={cyberion} alt="logo" className="root-image"/>
        }
        {
          clubProps?.franchise?.toLowerCase() === "traffic" &&
          <img src={traffic} alt="logo" className="root-image"/>
        }
        <h2>{clubProps.title}</h2>
      </div>
      <Comment text={comment} setText={setComment}/>
      <Emotions active={emotion} setActive={setEmotion}/>
      <Phone inputValue={phone} setInputValue={setPhone} anonymous={isAnonymous} setAnonymous={setIsAnonymous}/>
      <FileAttach file={file} setFile={setFile}/>
      <button className="submit" onClick={handleSend}>Відправити</button>
    </div>
  );
};

export default Root;