import './App.css';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './pages/Auth';
import Root from './pages/Root';
import Admin from './pages/Admin';
import NotFound from './pages/NotFound';
import Thanks from './pages/Thanks';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<Navigate to={'/login'}/>}/>
          <Route path={'/login'} element={<Auth/>}/>
          <Route path={'/admin'} element={<Admin/>}/>
          <Route path={'/404'} element={<NotFound/>}/>
          <Route path={'/thanks'} element={<Thanks/>}/>
          <Route path={'/:club'} element={<Root/>}/>
          <Route path={'*'} element={<Navigate to={'/'}/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
