import React from 'react';
import "../styles/Phone.css"
import {PhoneInput} from "react-international-phone";
import 'react-international-phone/style.css';

const Phone = ({inputValue, setInputValue, anonymous, setAnonymous}) => {
  return (
    <div className="phone">
      <h2 className="phone-heading">Телефон</h2>
      <PhoneInput
        defaultCountry="ua"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e)
        }}
        disabled={anonymous}
        inputClassName="input"
        className="input-wrapper"
        countrySelectorStyleProps={
          {
            style: {background: "transparent"},
            buttonStyle: {
              background: "transparent",
              border: "1px solid #595959",
              height: "100%"
            },

          }
        }
      />
      <label className="phone-label">
        <input type="checkbox" checked={anonymous} onChange={(e) => setAnonymous(e.target.checked)}/>
        <p>Анонімний відгук</p>
      </label>
    </div>
  );
};

export default Phone;