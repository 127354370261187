import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../styles/Admin.css"

const Admin = () => {
  const navigate = useNavigate()

  const [clubs, setClubs] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const [id, setId] = useState(0)
  const [address, setAddress] = useState("")
  const [title, setTitle] = useState("")
  const [franchise, setFranchise] = useState("")
  const [partnersEmail, setPartnersEmail] = useState("")

  useEffect(() => {
    if(localStorage.getItem('token')){
      const fetchData = async () => {
        await axios
          .get(`https://api.cyberion.com.ua/users/${localStorage.getItem("token")}`)
          .catch(err => {
            console.log(err);
            localStorage.removeItem("token")
            navigate("/login")
          })
      }

      fetchData()
    } else {
      navigate("/login")
    }
    // eslint-disable-next-line
  }, [localStorage])

  useEffect(() => {
    const fetchClubs = async () => {
      await axios
        .get("https://api.cyberion.com.ua/feedback/all")
        .then(res => {
          setClubs(res.data);
          console.log(res);
        })
        .catch(err => localStorage.removeItem("token"))
    }
    fetchClubs()
  }, []);

  const handleModal = () => setIsOpen(prev => !prev)
  const handleModalEdit = () => setIsOpenEdit(prev => {
    if(prev) {
      setTitle("")
      setFranchise("")
      setAddress("")
      setPartnersEmail("")
      setId(0)
    }
    return !prev
  })

  const handleCreate = async () => {
    await axios
      .post("https://api.cyberion.com.ua/feedback", {
        address,
        title,
        franchise,
        partnersEmail
      }, {
        headers: {
          'authorization': localStorage.getItem("token")
        }
      })
      .then(res => {
        setIsOpen(false)
        console.log(res.data);
        setClubs(prevState => [...prevState, res.data])
      })
      .catch(err => {
        console.log(err);
      })
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  const handleDelete = async (id) => {
    await axios
      .delete(`https://api.cyberion.com.ua/feedback/${id}`, {
        headers: {
          'authorization': localStorage.getItem("token")
        }
      })
      .then(res => {
        setClubs(prev => prev.filter(c => c.id !== id))
      })
      .catch(err => console.log(err))
  }

  const handleGetEdit = async (url) => {
    await axios
      .get(`https://api.cyberion.com.ua/feedback/${url}`)
      .then(res => {
        console.log(res)
        setTitle(res.data.title)
        setFranchise(res.data.franchise)
        setAddress(res.data.address)
        setPartnersEmail(res.data.partnersEmail)
        setId(res.data.id)
        setIsOpenEdit(true)
      })
      .catch(err => console.log(err.message))
  }

  const handleEdit = async(id) => {
    await axios
      .put("https://api.cyberion.com.ua/feedback", {
        clubId: id,
        address,
        title,
        franchise,
        partnersEmail
      }, {
        headers: {
          'authorization': localStorage.getItem("token")
        }
      })
      .then(res => {
        setIsOpenEdit(false)
        console.log(res.data);
        setTitle("")
        setFranchise("")
        setAddress("")
        setPartnersEmail("")
        setId(0)
        setClubs(prevState => {
          return [...prevState.filter(p => p.id !== res.data.id), res.data]
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <>
      {
        isOpen &&
        <div className="modal" onClick={handleModal}>
          <div className="modal-inner" onClick={e => e.stopPropagation()}>
            <div className="modal-head">
              <p>Створити клуб</p>
              <button className="modal-close" onClick={handleModal} />
            </div>
            <div className="modal-body">
              <label className="modal-label">
                <p>Введіть назву клубу(латиницею, вона буде у посиланні)</p>
                <input type="text" className="modal-input" value={title} onChange={e => setTitle(e.target.value)} placeholder="Назва клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть його франшизу</p>
                <input type="text" className="modal-input" value={franchise} onChange={e => setFranchise(e.target.value)} placeholder="Франшиза клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть його адресу</p>
                <input type="text" className="modal-input" value={address} onChange={e => setAddress(e.target.value)} placeholder="Адреса клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть партнерську пошту</p>
                <input type="text" className="modal-input" value={partnersEmail} onChange={e => setPartnersEmail(e.target.value)} placeholder="Партнерська пошта"/>
              </label>
            </div>
            <button onClick={handleCreate} className="modal-btn">
              Створити
            </button>
          </div>
        </div>
      }
      {
        isOpenEdit &&
        <div className="modal" onClick={handleModalEdit}>
          <div className="modal-inner" onClick={e => e.stopPropagation()}>
            <div className="modal-head">
              <p>Змінити клуб {title}</p>
              <button className="modal-close" onClick={handleModalEdit} />
            </div>
            <div className="modal-body">
              <label className="modal-label">
                <p>Введіть назву клубу(латиницею, вона буде у посиланні)</p>
                <input type="text" className="modal-input" value={title} onChange={e => setTitle(e.target.value)} placeholder="Назва клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть його франшизу</p>
                <input type="text" className="modal-input" value={franchise} onChange={e => setFranchise(e.target.value)} placeholder="Франшиза клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть його адресу</p>
                <input type="text" className="modal-input" value={address} onChange={e => setAddress(e.target.value)} placeholder="Адреса клубу"/>
              </label>
              <label className="modal-label">
                <p>Введіть партнерську пошту</p>
                <input type="text" className="modal-input" value={partnersEmail} onChange={e => setPartnersEmail(e.target.value)} placeholder="Партнерська пошта"/>
              </label>
            </div>
            <button onClick={() => handleEdit(id)} className="modal-btn">
              Зберегти
            </button>
          </div>
        </div>
      }
      <div className="admin">
        <div className="admin-head">
          <h2>Список клубів:</h2>
          <button
            className="admin-button"
            onClick={handleModal}
          >
            Створити
          </button>
        </div>
        <div className="admin-list">
          {
            clubs && clubs.length &&
            clubs.map(c => (
              <div className="club-item" key={c.id}>
                <div className="club-left">
                  <h3>{c.title}</h3>
                </div>
                <div className="club-right">
                  <button className="club-edit" onClick={() => handleGetEdit(c.title)}/>
                  <button className="club-copy" onClick={() => copyToClipboard(`https://feedbacks.cyberion.com.ua/${c.title}`)}/>
                  <button className="club-delete" onClick={() => handleDelete(c.id)}/>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Admin;