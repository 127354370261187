import React from 'react';
import '../styles/Emotions.css'

import dislike from "../images/dislike.svg"
import neutral from "../images/neutral.svg"
import like from "../images/like.svg"

const Emotions = ({active, setActive}) => {
  return (
      <div className={"emotions"}>
        <div className="emotions-head">
          <h2 className="emotions-heading">Твої емоції після відвідування клубу</h2>
        </div>
        <div className="emotions-body">
          <button onClick={() => setActive(0)} className={`emotions-button ${active === 0 ? 'emotions-button_active' : ''}`} style={{ backgroundImage: `url("${dislike}")` }} />
          <button onClick={() => setActive(1)} className={`emotions-button ${active === 1 ? 'emotions-button_active' : ''}`} style={{ backgroundImage: `url("${neutral}")` }} />
          <button onClick={() => setActive(2)} className={`emotions-button ${active === 2 ? 'emotions-button_active' : ''}`} style={{ backgroundImage: `url("${like}")` }} />
        </div>
      </div>
  );
};

export default Emotions;