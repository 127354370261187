import React from 'react';
import "../styles/Comment.css"

const Comment = ({text, setText}) => {
  return (
    <div className={"comment"}>
      <h1 className="comment-head">
        <span className="span-red">* </span>
        Друзі, що вам сподобалось/не сподобалось у нашому клубі?<br/>
        Допоможіть нам стати краще!
      </h1>
      <textarea value={text} onChange={(e) => setText(e.target.value)} className="comment-text" placeholder="Твої враження"/>
    </div>
  );
};

export default Comment;