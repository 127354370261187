import React from 'react';
import "../styles/FileAttach.css"

const FileAttach = ({file, setFile}) => {
  return (
    <div>
      <label htmlFor="attach" className="label-attach">
        <h2 className="file-heading">Додати файл</h2><p>{file !== null && file !== undefined ? file.name : ''}</p>
        <input id="attach" type="file" onChange={(e) => setFile(e?.target?.files[0])} className="input-attach" />
      </label>
    </div>
  );
};

export default FileAttach;