import React from 'react';
import "../styles/Thanks.css"

const Thanks = () => {
  return (
    <div className="thanks">
      <div className="thanks-content">
        <h1>Дякуємо за ваш відгук і що допомагаєте нам стати краще!</h1>
      </div>
    </div>
  );
};

export default Thanks;